// LOGIN
export const LOG_IN = "LOG_IN";
export const LOG_IN_FAIL = "LOG_IN_FAIL";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";

// UPDATE_PROFILE
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

// VIEW_SETTING
export const VIEW_SETTING = "VIEW_SETTING";
export const VIEW_SETTING_FAIL = "VIEW_SETTING_FAIL";
export const VIEW_SETTING_SUCCESS = "VIEW_SETTING_SUCCESS";

// UPDATE_SETTING
export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_SETTING_FAIL = "UPDATE_SETTING_FAIL";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";

// DAILY_MATCHES
export const DAILY_MATCHES = "DAILY_MATCHES";
export const DAILY_MATCHES_FAIL = "DAILY_MATCHES_FAIL";
export const DAILY_MATCHES_SUCCESS = "DAILY_MATCHES_SUCCESS";

// MANAGE_UPI
export const MANAGE_UPI = "MANAGE_UPI";
export const MANAGE_UPI_FAIL = "MANAGE_UPI_FAIL";
export const MANAGE_UPI_SUCCESS = "MANAGE_UPI_SUCCESS";

// ALL_RESOURCES
export const ALL_RESOURCES = "ALL_RESOURCES";
export const ALL_RESOURCES_FAIL = "ALL_RESOURCES_FAIL";
export const ALL_RESOURCES_SUCCESS = "ALL_RESOURCES_SUCCESS";

// -------------------------------- Tournament --------------------------------//

// TOURNAMENT
export const TOURNAMENT = "TOURNAMENT";
export const TOURNAMENT_FAIL = "TOURNAMENT_FAIL";
export const TOURNAMENT_SUCCESS = "TOURNAMENT_SUCCESS";

// ADD_TOURNAMENT
export const ADD_TOURNAMENT = "ADD_TOURNAMENT";
export const ADD_TOURNAMENT_FAIL = "ADD_TOURNAMENT_FAIL";
export const ADD_TOURNAMENT_SUCCESS = "ADD_TOURNAMENT_SUCCESS";

// DELETE_TOURNAMENT
export const DELETE_TOURNAMENT = "DELETE_TOURNAMENT";
export const DELETE_TOURNAMENT_FAIL = "DELETE_TOURNAMENT_FAIL";
export const DELETE_TOURNAMENT_SUCCESS = "DELETE_TOURNAMENT_SUCCESS";

// UPDATE_TOUR_STATUS
export const UPDATE_TOUR_STATUS = "UPDATE_TOUR_STATUS";
export const UPDATE_TOUR_STATUS_FAIL = "UPDATE_TOUR_STATUS_FAIL";
export const UPDATE_TOUR_STATUS_SUCCESS = "UPDATE_TOUR_STATUS_SUCCESS";

// UPDATE_TOUR
export const UPDATE_TOUR = "UPDATE_TOUR";
export const UPDATE_TOUR_FAIL = "UPDATE_TOUR_FAIL";
export const UPDATE_TOUR_SUCCESS = "UPDATE_TOUR_SUCCESS";

// VIEW_TOUR
export const VIEW_TOUR = "VIEW_TOUR";
export const VIEW_TOUR_FAIL = "VIEW_TOUR_FAIL";
export const VIEW_TOUR_SUCCESS = "VIEW_TOUR_SUCCESS";

// GET TEAM BY GAME
export const GET_TEAM_BY_GAME = "GET_TEAM_BY_GAME";
export const GET_TEAM_BY_GAME_FAIL = "GET_TEAM_BY_GAME_FAIL";
export const GET_TEAM_BY_GAME_SUCCESS = "GET_TEAM_BY_GAME_SUCCESS";

// JOIN TEAM IN TOUR
export const JOIN_TEAM_IN_TOUR = "JOIN_TEAM_IN_TOUR";
export const JOIN_TEAM_IN_TOUR_FAIL = "JOIN_TEAM_IN_TOUR_FAIL";
export const JOIN_TEAM_IN_TOUR_SUCCESS = "JOIN_TEAM_IN_TOUR_SUCCESS";

// DELETE_TOURNAMENT_TEAM
export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";

// JOINED DELETE_TOURNAMENT_TEAM
export const JOINED_DELETE_TEAM = "JOINED_DELETE_TEAM";
export const JOINED_DELETE_TEAM_FAIL = "JOINED_DELETE_TEAM_FAIL";
export const JOINED_DELETE_TEAM_SUCCESS = "JOINED_DELETE_TEAM_SUCCESS";

// UPDATE_ROOM_ID
export const UPDATE_ROOM_ID = "UPDATE_ROOM_ID";
export const UPDATE_ROOM_ID_FAIL = "UPDATE_ROOM_ID_FAIL";
export const UPDATE_ROOM_ID_SUCCESS = "UPDATE_ROOM_ID_SUCCESS";

// UPDATE_SETTLEMENT_STATUS
export const UPDATE_SETTLEMENT_STATUS = "UPDATE_SETTLEMENT_STATUS";
export const UPDATE_SETTLEMENT_STATUS_FAIL = "UPDATE_SETTLEMENT_STATUS_FAIL";
export const UPDATE_SETTLEMENT_STATUS_SUCCESS =
  "UPDATE_SETTLEMENT_STATUS_SUCCESS";

// GAME_PLAYER_LIST
export const GAME_PLAYER_LIST = "GAME_PLAYER_LIST";
export const GAME_PLAYER_LIST_FAIL = "GAME_PLAYER_LIST_FAIL";
export const GAME_PLAYER_LIST_SUCCESS = "GAME_PLAYER_LIST_SUCCESS";

// GAME_PLAYER_VIEW
export const GAME_PLAYER_VIEW = "GAME_PLAYER_VIEW";
export const GAME_PLAYER_VIEW_FAIL = "GAME_PLAYER_VIEW_FAIL";
export const GAME_PLAYER_VIEW_SUCCESS = "GAME_PLAYER_VIEW_SUCCESS";

// -------------------------------- Games --------------------------------//

// GAME_LIST
export const GAME_LIST = "GAME_LIST";
export const GAME_LIST_FAIL = "GAME_LIST_FAIL";
export const GAME_LIST_SUCCESS = "GAME_LIST_SUCCESS";

// ADD_GAME
export const ADD_GAME = "ADD_GAME";
export const ADD_GAME_FAIL = "ADD_GAME_FAIL";
export const ADD_GAME_SUCCESS = "ADD_GAME_SUCCESS";

// ADD_PLACEMENT_POINTS
export const ADD_PLACEMENT_POINTS = "ADD_PLACEMENT_POINTS";
export const ADD_PLACEMENT_POINTS_FAIL = "ADD_PLACEMENT_POINTS_FAIL";
export const ADD_PLACEMENT_POINTS_SUCCESS = "ADD_PLACEMENT_POINTS_SUCCESS";

// GET_PLACEMENT_POINTS
export const GET_PLACEMENT_POINTS = "GET_PLACEMENT_POINTS";
export const GET_PLACEMENT_POINTS_FAIL = "GET_PLACEMENT_POINTS_FAIL";
export const GET_PLACEMENT_POINTS_SUCCESS = "GET_PLACEMENT_POINTS_SUCCESS";

// UPDATE_GAME
export const UPDATE_GAME = "UPDATE_GAME";
export const UPDATE_GAME_FAIL = "UPDATE_GAME_FAIL";
export const UPDATE_GAME_SUCCESS = "UPDATE_GAME_SUCCESS";

// UPDATE_GAME_STATUS
export const UPDATE_GAME_STATUS = "UPDATE_GAME_STATUS";
export const UPDATE_GAME_STATUS_FAIL = "UPDATE_GAME_STATUS_FAIL";
export const UPDATE_GAME_STATUS_SUCCESS = "UPDATE_GAME_STATUS_SUCCESS";

// DELETE_GAME
export const DELETE_GAME = "DELETE_GAME";
export const DELETE_GAME_FAIL = "DELETE_GAME_FAIL";
export const DELETE_GAME_SUCCESS = "DELETE_GAME_SUCCESS";

// VIEW_GAME
export const VIEW_GAME = "VIEW_GAME";
export const VIEW_GAME_FAIL = "VIEW_GAME_FAIL";
export const VIEW_GAME_SUCCESS = "VIEW_GAME_SUCCESS";

// TEAM_LIST
export const TEAM_LIST = "TEAM_LIST";
export const TEAM_LIST_FAIL = "TEAM_LIST_FAIL";
export const TEAM_LIST_SUCCESS = "TEAM_LIST_SUCCESS";

// USER_TEAM_LIST
export const USER_TEAM_LIST = "USER_TEAM_LIST";
export const USER_TEAM_LIST_FAIL = "USER_TEAM_LIST_FAIL";
export const USER_TEAM_LIST_SUCCESS = "USER_TEAM_LIST_SUCCESS";

// USER_PASS_LIST
export const USER_PASS_LIST = "USER_PASS_LIST";
export const USER_PASS_LIST_FAIL = "USER_PASS_LIST_FAIL";
export const USER_PASS_LIST_SUCCESS = "USER_PASS_LIST_SUCCESS";

// ALL_TEAM_LIST
export const ALL_TEAM_LIST = "ALL_TEAM_LIST";
export const ALL_TEAM_LIST_FAIL = "ALL_TEAM_LIST_FAIL";
export const ALL_TEAM_LIST_SUCCESS = "ALL_TEAM_LIST_SUCCESS";

// TEAM_LOG_LIST
export const TEAM_LOG_LIST = "TEAM_LOG_LIST";
export const TEAM_LOG_LIST_FAIL = "TEAM_LOG_LIST_FAIL";
export const TEAM_LOG_LIST_SUCCESS = "TEAM_LOG_LIST_SUCCESS";

// TDM_LOG_LIST
export const TDM_LOG_LIST = "TDM_LOG_LIST";
export const TDM_LOG_LIST_FAIL = "TDM_LOG_LIST_FAIL";
export const TDM_LOG_LIST_SUCCESS = "TDM_LOG_LIST_SUCCESS";

// DELETE_TDM_LOG
export const DELETE_TDM_LOG = "DELETE_TDM_LOG";
export const DELETE_TDM_LOG_FAIL = "DELETE_TDM_LOG_FAIL";
export const DELETE_TDM_LOG_SUCCESS = "DELETE_TDM_LOG_SUCCESS";

// TDM_PLAYER_LISTS
export const TDM_PLAYER_LISTS = "TDM_PLAYER_LISTS";
export const TDM_PLAYER_LISTS_FAIL = "TDM_PLAYER_LISTS_FAIL";
export const TDM_PLAYER_LISTS_SUCCESS = "TDM_PLAYER_LISTS_SUCCESS";

// TDM_PLAYER_DETAILS
export const TDM_PLAYER_DETAILS = "TDM_PLAYER_DETAILS";
export const TDM_PLAYER_DETAILS_FAIL = "TDM_PLAYER_DETAILS_FAIL";
export const TDM_PLAYER_DETAILS_SUCCESS = "TDM_PLAYER_DETAILS_SUCCESS";

// GAME_PLAYER_LISTS
export const GAME_PLAYER_LISTS = "GAME_PLAYER_LISTS";
export const GAME_PLAYER_LISTS_FAIL = "GAME_PLAYER_LISTS_FAIL";
export const GAME_PLAYER_LISTS_SUCCESS = "GAME_PLAYER_LISTS_SUCCESS";

// GAME_PLAYER_DETAILS
export const GAME_PLAYER_DETAILS = "GAME_PLAYER_DETAILS";
export const GAME_PLAYER_DETAILS_FAIL = "GAME_PLAYER_DETAILS_FAIL";
export const GAME_PLAYER_DETAILS_SUCCESS = "GAME_PLAYER_DETAILS_SUCCESS";

// TEAM_VIEW
export const TEAM_VIEW = "TEAM_VIEW";
export const TEAM_VIEW_FAIL = "TEAM_VIEW_FAIL";
export const TEAM_VIEW_SUCCESS = "TEAM_VIEW_SUCCESS";

// PLAYER_LIST
export const PLAYER_LIST = "PLAYER_LIST";
export const PLAYER_LIST_FAIL = "PLAYER_LIST_FAIL";
export const PLAYER_LIST_SUCCESS = "PLAYER_LIST_SUCCESS";

// PLAYER_VIEW
export const PLAYER_VIEW = "PLAYER_VIEW";
export const PLAYER_VIEW_FAIL = "PLAYER_VIEW_FAIL";
export const PLAYER_VIEW_SUCCESS = "PLAYER_VIEW_SUCCESS";

// -------------------------------- Game Types --------------------------------//
// ALL_GAME_LIST
export const ALL_GAME_LIST = "ALL_GAME_LIST";
export const ALL_GAME_LIST_FAIL = "ALL_GAME_LIST_FAIL";
export const ALL_GAME_LIST_SUCCESS = "ALL_GAME_LIST_SUCCESS";

// -------------------------------- Game Types --------------------------------//
// HOST_LIST
export const HOST_LIST = "HOST_LIST";
export const HOST_LIST_FAIL = "HOST_LIST_FAIL";
export const HOST_LIST_SUCCESS = "HOST_LIST_SUCCESS";

// -------------------------------- Banners --------------------------------//
// BANNER_LIST
export const BANNER_LIST = "BANNER_LIST";
export const BANNER_LIST_FAIL = "BANNER_LIST_FAIL";
export const BANNER_LIST_SUCCESS = "BANNER_LIST_SUCCESS";

// ADD_BANNER
export const ADD_BANNER = "ADD_BANNER ";
export const ADD_BANNER_FAIL = "ADD_BANNER_FAIL";
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS";

// UPDATE_SPONSOR
export const UPDATE_BANNER = "UPDATE_BANNER";
export const UPDATE_BANNER_FAIL = "UPDATE_BANNER_FAIL";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";

// DELETE_BANNER
export const DELETE_BANNER = "DELETE_BANNER ";
export const DELETE_BANNER_FAIL = "DELETE_BANNER _FAIL";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER _SUCCESS";

// UPDATE_BANNER_STATUS
export const UPDATE_BANNER_STATUS = "UPDATE_BANNER_STATUS";
export const UPDATE_BANNER_STATUS_FAIL = "UPDATE_BANNER_STATUS_FAIL";
export const UPDATE_BANNER_STATUS_SUCCESS = "UPDATE_BANNER_STATUS_SUCCESS";

// -------------------------------- Game Types --------------------------------//

// GAME_TYPE_LIST
export const GAME_TYPE_LIST = "GAME_TYPE_LIST";
export const GAME_TYPE_LIST_FAIL = "GAME_TYPE_LIST_FAIL";
export const GAME_TYPE_LIST_SUCCESS = "GAME_TYPE_LIST_SUCCESS";

// -------------------------------- Users --------------------------------//

// USER_LIST
export const USER_LIST = "USER_LIST";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";

// USER_VIEW
export const USER_VIEW = "USER_VIEW";
export const USER_VIEW_FAIL = "USER_VIEW_FAIL";
export const USER_VIEW_SUCCESS = "USER_VIEW_SUCCESS";

// VERIFY_USER
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";

// MANAGE_USER_SUBSCRIIPTION
export const MANAGE_USER_SUBSCRIIPTION = "MANAGE_USER_SUBSCRIIPTION";
export const MANAGE_USER_SUBSCRIIPTION_FAIL = "MANAGE_USER_SUBSCRIIPTION_FAIL";
export const MANAGE_USER_SUBSCRIIPTION_SUCCESS =
  "MANAGE_USER_SUBSCRIIPTION_SUCCESS";

// TRANSACTION_LOGS
export const TRANSACTION_LOGS = "TRANSACTION_LOGS";
export const TRANSACTION_LOGS_FAIL = "TRANSACTION_LOGS_FAIL";
export const TRANSACTION_LOGS_SUCCESS = "TRANSACTION_LOGS_SUCCESS";

// WITHDRAW_REQUEST
export const WITHDRAW_REQUEST = "WITHDRAW_REQUEST";
export const WITHDRAW_REQUEST_FAIL = "WITHDRAW_REQUEST_FAIL";
export const WITHDRAW_REQUEST_SUCCESS = "WITHDRAW_REQUEST_SUCCESS";

// GET_BENEFICIARY
export const GET_BENEFICIARY = "GET_BENEFICIARY";
export const GET_BENEFICIARY_FAIL = "GET_BENEFICIARY_FAIL";
export const GET_BENEFICIARY_SUCCESS = "GET_BENEFICIARY_SUCCESS";

// PAYOUT
export const PAYOUT = "PAYOUT";
export const PAYOUT_FAIL = "PAYOUT_FAIL";
export const PAYOUT_SUCCESS = "PAYOUT_SUCCESS";


// APPROVE_REQUEST
export const APPROVE_REQUEST = "APPROVE_REQUEST";
export const APPROVE_REQUEST_FAIL = "APPROVE_REQUEST_FAIL";
export const APPROVE_REQUEST_SUCCESS = "APPROVE_REQUEST_SUCCESS";

// UPDATE_USER_STATUS
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_USER_STATUS_FAIL = "UPDATE_USER_STATUS_FAIL";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";

// DELETE_USER
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

// UPDATE_USER
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

// UPDATE_USER_WALLET
export const UPDATE_USER_WALLET = "UPDATE_USER_WALLET";
export const UPDATE_USER_WALLET_FAIL = "UPDATE_USER_WALLET_FAIL";
export const UPDATE_USER_WALLET_SUCCESS = "UPDATE_USER_WALLET_SUCCESS";

// -------------------------------- Notification --------------------------------//

// NOTIFICATION_LIST
export const NOTIFICATION_LIST = "NOTIFICATION_LIST";
export const NOTIFICATION_LIST_FAIL = "NOTIFICATION_LIST_FAIL";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";

// DELETE_NOTIFICATION
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";

// ADD_NOTIFICATION
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_NOTIFICATION_FAIL = "ADD_NOTIFICATION_FAIL";
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";

// STATUS_NOTIFICATION
export const STATUS_NOTIFICATION = "STATUS_NOTIFICATION";
export const STATUS_NOTIFICATION_FAIL = "STATUS_NOTIFICATION_FAIL";
export const STATUS_NOTIFICATION_SUCCESS = "STATUS_NOTIFICATION_SUCCESS";

// ---------------------- Team Rank ------------------------------ //

// UPDATE_TEAM_RANK
export const UPDATE_TEAM_RANK = "UPDATE_TEAM_RANK";
export const UPDATE_TEAM_RANK_FAIL = "UPDATE_TEAM_RANK_FAIL";
export const UPDATE_TEAM_RANK_SUCCESS = "UPDATE_TEAM_RANK_SUCCESS";

// UPDATE_TEAM_STATUS
export const UPDATE_TEAM_STATUS = "UPDATE_TEAM_STATUS";
export const UPDATE_TEAM_STATUS_FAIL = "UPDATE_TEAM_STATUS_FAIL";
export const UPDATE_TEAM_STATUS_SUCCESS = "UPDATE_TEAM_STATUS_SUCCESS";

// --------------------------------sponsor---------------------//

// SPONSOR_LIST
export const SPONSOR_LIST = "SPONSOR_LIST";
export const SPONSOR_LIST_FAIL = "SPONSOR_LIST_FAIL";
export const SPONSOR_LIST_SUCCESS = "SPONSOR_LIST_SUCCESS";

// ADD_SPONSOR
export const ADD_SPONSOR = "ADD_SPONSOR ";
export const ADD_SPONSOR_FAIL = "ADD_SPONSOR_FAIL";
export const ADD_SPONSOR_SUCCESS = "ADD_SPONSOR_SUCCESS";

// UPDATE_SPONSOR
export const UPDATE_SPONSOR = "UPDATE_SPONSOR";
export const UPDATE_SPONSOR_FAIL = "UPDATE_SPONSOR_FAIL";
export const UPDATE_SPONSOR_SUCCESS = "UPDATE_SPONSOR_SUCCESS";

// DELETE_SPONSOR
export const DELETE_SPONSOR = "DELETE_SPONSOR ";
export const DELETE_SPONSOR_FAIL = "DELETE_SPONSOR _FAIL";
export const DELETE_SPONSOR_SUCCESS = "DELETE_SPONSOR _SUCCESS";

// UPDATE_SPONSOR_STATUS
export const UPDATE_SPONSOR_STATUS = "UPDATE_SPONSOR_STATUS";
export const UPDATE_SPONSOR_STATUS_FAIL = "UPDATE_SPONSOR_STATUS_FAIL";
export const UPDATE_SPONSOR_STATUS_SUCCESS = "UPDATE_SPONSOR_STATUS_SUCCESS";

// TEAM_PLAYER_REMOVE
export const DELETE_TEAM_Player = "DELETE_TEAM_Player";
export const DELETE_TEAM_Player_FAIL = "DELETE_TEAM_Player_FAIL";
export const DELETE_TEAM_Player_SUCCESS = "DELETE_TEAM_Player_SUCCESS";

// RECORD Count
export const RECORD_COUNT = "RECORD_COUNT";
export const RECORD_COUNT_FAIL = "RECORD_COUNT_FAIL";
export const RECORD_COUNT_SUCCESS = "RECORD_COUNT_SUCCESS";
// Resgitration RECORD Count
export const REGISTRATION_RECORD_COUNT = "REGISTRATION_RECORD_COUNT";
export const REGISTRATION_RECORD_COUNT_FAIL = "REGISTRATION_RECORD_COUNT_FAIL";
export const REGISTRATION_RECORD_COUNT_SUCCESS =
  "REGISTRATION_RECORD_COUNT_SUCCESS";

///Device count
export const DEVICE_RECORD_COUNT = "DEVICE_RECORD_COUNT";
export const DEVICE_RECORD_COUNT_FAIL = "DEVICE_RECORD_COUNT_FAIL";
export const DEVICE_RECORD_COUNT_SUCCESS = "DEVICE_RECORD_COUNT_SUCCESS";

// TOTAL AMOUNT
export const TOTAL_AMOUNT = "TOTAL_AMOUNT";
export const TOTAL_AMOUNT_FAIL = "TOTAL_AMOUNT_FAIL";
export const TOTAL_AMOUNT_SUCCESS = "TOTAL_AMOUNT_SUCCESS";

// TRANSACTION LIST
export const TRANSACTION_LIST = "TRANSACTION_LIST";
export const TRANSACTION_LIST_FAIL = "TRANSACTION_LIST_FAIL";
export const TRANSACTION_LIST_SUCCESS = "TRANSACTION_LIST_SUCCESS";

// TRANSACTION LIST BY ID
export const TRANSACTION_LIST_BY_Id = "TRANSACTION_LIST_BY_Id";
export const TRANSACTION_LIST_FAIL_BY_Id = "TRANSACTION_LIST_FAIL_BY_Id";
export const TRANSACTION_LIST_SUCCESS_BY_Id = "TRANSACTION_LIST_SUCCESS_BY_Id";

// ADD SUPPORT
export const ADD_SUPPORT = "ADD_SUPPORT";
export const ADD_SUPPORT_FAIL = "ADD_SUPPORT_FAIL";
export const ADD_SUPPORT_SUCCESS = "ADD_SUPPORT_SUCCESS";

// View SUPPORT
export const View_SUPPORT = "View_SUPPORT";
export const View_SUPPORT_FAIL = "View_SUPPORT_FAIL";
export const View_SUPPORT_SUCCESS = "View_SUPPORT_SUCCESS";

// UPDATE STREAMING LINK
export const UPDATE_STREAMING_LINK = "UPDATE_STREAMING_LINK";
export const UPDATE_STREAMING_LINK_FAIL = "UPDATE_STREAMING_LINK_FAIL";
export const UPDATE_STREAMING_LINK_SUCCESS = "UPDATE_STREAMING_LINK_SUCCESS";

// ------------  League Section ------------- //
export const GET_STAGE_LIST = "GET_STAGE_LIST";
export const GET_STAGE_LIST_FAIL = "GET_STAGE_LIST_FAIL";
export const GET_STAGE_LIST_SUCCESS = "GET_STAGE_LIST_SUCCESS";

export const GET_LEAGIE_TOUR_LIST = "GET_LEAGIE_TOUR_LIST";
export const GET_LEAGIE_TOUR_LIST_FAIL = "GET_LEAGIE_TOUR_LIST_FAIL";
export const GET_LEAGIE_TOUR_LIST_SUCCESS = "GET_LEAGIE_TOUR_LIST_SUCCESS";

export const GET_LEAGIE_TOUR_TEAM_LIST = "GET_LEAGIE_TOUR_TEAM_LIST";
export const GET_LEAGIE_TOUR_TEAM_LIST_FAIL = "GET_LEAGIE_TOUR_TEAM_LIST_FAIL";
export const GET_LEAGIE_TOUR_TEAM_LIST_SUCCESS =
  "GET_LEAGIE_TOUR_TEAM_LIST_SUCCESS";

export const STAGE_STATUS_UPDATE = "STAGE_STATUS_UPDATE";
export const STAGE_STATUS_UPDATE_FAIL = "STAGE_STATUS_UPDATE_FAIL";
export const STAGE_STATUS_UPDATE_SUCCESS = "STAGE_STATUS_UPDATE_SUCCESS";

export const LEAGUE_TOUR_STATUS_UPDATE = "LEAGUE_TOUR_STATUS_UPDATE";
export const LEAGUE_TOUR_STATUS_UPDATE_FAIL = "LEAGUE_TOUR_STATUS_UPDATE_FAIL";
export const LEAGUE_TOUR_STATUS_UPDATE_SUCCESS =
  "LEAGUE_TOUR_STATUS_UPDATE_SUCCESS";

export const STAGE_REMOVE = "STAGE_REMOVE";
export const STAGE_REMOVE_FAIL = "STAGE_REMOVE_FAIL";
export const STAGE_REMOVE_SUCCESS = "STAGE_REMOVE_SUCCESS";

export const STAGE_RESULT = "STAGE_RESULT";
export const STAGE_RESULT_FAIL = "STAGE_RESULT_FAIL";
export const STAGE_RESULT_SUCCESS = "STAGE_RESULT_SUCCESS";

export const VIEW_LEAGUE_TOUR = "VIEW_LEAGUE_TOUR";
export const VIEW_LEAGUE_TOUR_FAIL = "VIEW_LEAGUE_TOUR_FAIL";
export const VIEW_LEAGUE_TOUR_SUCCESS = "VIEW_LEAGUE_TOUR_SUCCESS";

export const GET_TOUR_STAGE = "GET_TOUR_STAGE";
export const GET_TOUR_STAGE_FAIL = "GET_TOUR_STAGE_FAIL";
export const GET_TOUR_STAGE_SUCCESS = "GET_TOUR_STAGE_SUCCESS";

export const UPDATE_TOUR_STAGE = "UPDATE_TOUR_STAGE";
export const UPDATE_TOUR_STAGE_FAIL = "UPDATE_TOUR_STAGE_FAIL";
export const UPDATE_TOUR_STAGE_SUCCESS = "UPDATE_TOUR_STAGE_SUCCESS";

///Delete Tour Stage

export const DELETE_TOUR_STAGE = "DELETE_TOUR_STAGE";
export const DELETE_TOUR_STAGE_FAIL = "DELETE_TOUR_STAGE_FAIL";
export const DELETE_TOUR_STAGE_SUCCESS = "DELETE_TOUR_STAGE_SUCCESS";

export const LEAGUE_TOUR_REMOVE = "LEAGUE_TOUR_REMOVE";
export const LEAGUE_TOUR_REMOVE_FAIL = "LEAGUE_TOUR_REMOVE_FAIL";
export const LEAGUE_TOUR_REMOVE_SUCCESS = "LEAGUE_TOUR_REMOVE_SUCCESS";

export const CREATE_STAGE = "CREATE_STAGE";
export const CREATE_STAGE_FAIL = "CREATE_STAGE_FAIL";
export const CREATE_STAGE_SUCCESS = "CREATE_STAGE_SUCCESS";

export const CREATE_LEAGUE_TOUR = "CREATE_LEAGUE_TOUR";
export const CREATE_LEAGUE_TOUR_FAIL = "CREATE_LEAGUE_TOUR_FAIL";
export const CREATE_LEAGUE_TOUR_SUCCESS = "CREATE_LEAGUE_TOUR_SUCCESS";

export const UPDATE_STAGE = "UPDATE_STAGE";
export const UPDATE_STAGE_FAIL = "UPDATE_STAGE_FAIL";
export const UPDATE_STAGE_SUCCESS = "UPDATE_STAGE_SUCCESS";

export const UPDATE_LEAGUE_TOUR = "UPDATE_LEAGUE_TOUR";
export const UPDATE_LEAGUE_TOUR_FAIL = "UPDATE_LEAGUE_TOUR_FAIL";
export const UPDATE_LEAGUE_TOUR_SUCCESS = "UPDATE_LEAGUE_TOUR_SUCCESS";
/// ROUND

///Get Rounds
export const GET_STAGE_ROUND = "GET_STAGE_ROUND";
export const GET_STAGE_ROUND_FAIL = "GET_STAGE_ROUND_FAIL";
export const GET_STAGE_ROUND_SUCCESS = "GET_STAGE_ROUND_SUCCESS";

///GET teams stagewise

export const GET_TEAMS_STAGE_WISE = "GET_TEAMS_STAGE_WISE";
export const GET_TEAMS_STAGE_WISE_FAIL = "GET_TEAMS_STAGE_WISE_FAIL";
export const GET_TEAMS_STAGE_WISE_SUCCESS = "GET_TEAMS_STAGE_WISE_SUCCESS";

/// Add Round
export const ADD_STAGE_ROUND = "ADD_STAGE_ROUND";
export const ADD_STAGE_ROUND_FAIL = "ADD_STAGE_ROUND_FAIL";
export const ADD_STAGE_ROUND_SUCCESS = "ADD_STAGE_ROUND_SUCCESS";

///Delete Tour Round

export const DELETE_STAGE_ROUND = "DELETE_STAGE_ROUND";
export const DELETE_STAGE_ROUND_FAIL = "DELETE_STAGE_ROUND_FAIL";
export const DELETE_STAGE_ROUND_SUCCESS = "DELETE_STAGE_ROUND_SUCCESS";

/// Add Round Group
export const ADD_ROUND_GROUP = "ADD_ROUND_GROUP";
export const ADD_ROUND_GROUP_FAIL = "ADD_ROUND_GROUP_FAIL";
export const ADD_ROUND_GROUP_SUCCESS = "ADD_ROUND_GROUP_SUCCESS";

///view roundGruop
export const VIEW_ROUND_GROUP = "VIEW_ROUND_GROUP";
export const VIEW_ROUND_GROUP_FAIL = "VIEW_ROUND_GROUP_FAIL";
export const VIEW_ROUND_GROUP_SUCCESS = "VIEW_ROUND_GROUP_SUCCESS";

export const UPDATE_LOBBY_RESULT = "UPDATE_LOBBY_RESULT";
export const UPDATE_LOBBY_RESULT_SUCCESS = "UPDATE_LOBBY_RESULT_SUCCESS";
export const UPDATE_LOBBY_RESULT_FAIL = "UPDATE_LOBBY_RESULT_FAIL";

export const UPDATE_LOBBY_STATUS = "UPDATE_LOBBY_STATUS";
export const UPDATE_LOBBY_STATUS_SUCCESS = "UPDATE_LOBBY_STATUS_SUCCESS";
export const UPDATE_LOBBY_STATUS_FAIL = "UPDATE_LOBBY_STATUS_FAIL";

// ---------------------------------------------------- Subscription

// SUBSCRIPTION_LIST
export const SUBSCRIPTION_LIST = "SUBSCRIPTION_LIST";
export const SUBSCRIPTION_LIST_FAIL = "SUBSCRIPTION_LIST_FAIL";
export const SUBSCRIPTION_LIST_SUCCESS = "SUBSCRIPTION_LIST_SUCCESS";

// ADD_SUBSCRIPTION
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION ";
export const ADD_SUBSCRIPTION_FAIL = "ADD_SUBSCRIPTION_FAIL";
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS";

// UPDATE_SPONSOR
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";

// DELETE_SUBSCRIPTION
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION ";
export const DELETE_SUBSCRIPTION_FAIL = "DELETE_SUBSCRIPTION _FAIL";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION _SUCCESS";

// UPDATE_SUBSCRIPTION_STATUS
export const UPDATE_SUBSCRIPTION_STATUS = "UPDATE_SUBSCRIPTION_STATUS";
export const UPDATE_SUBSCRIPTION_STATUS_FAIL =
  "UPDATE_SUBSCRIPTION_STATUS_FAIL";
export const UPDATE_SUBSCRIPTION_STATUS_SUCCESS =
  "UPDATE_SUBSCRIPTION_STATUS_SUCCESS";

// GET_SUBSCRIPTION_COUNT
export const GET_SUBSCRIPTION_COUNT = "GET_SUBSCRIPTION_COUNT";
export const GET_SUBSCRIPTION_COUNT_FAIL = "GET_SUBSCRIPTION_COUNT_FAIL";
export const GET_SUBSCRIPTION_COUNT_SUCCESS = "GET_SUBSCRIPTION_COUNT_SUCCESS";

// GET_SUBSCRIBERS_LIST
export const GET_SUBSCRIBERS_LIST = "GET_SUBSCRIBERS_LIST";
export const GET_SUBSCRIBERS_LIST_FAIL = "GET_SUBSCRIBERS_LIST_FAIL";
export const GET_SUBSCRIBERS_LIST_SUCCESS = "GET_SUBSCRIBERS_LIST_SUCCESS";

// GET_LEADERBOARD_RESULT_LIST
export const GET_LEADERBOARD_RESULT_LIST = "GET_LEADERBOARD_RESULT_LIST";
export const GET_LEADERBOARD_RESULT_LIST_FAIL =
  "GET_LEADERBOARD_RESULT_LIST_FAIL";
export const GET_LEADERBOARD_RESULT_LIST_SUCCESS =
  "GET_LEADERBOARD_RESULT_LIST_SUCCESS";

// -------------------------- Battle Pass

export const GET_BATTLEPASS_LIST = "GET_BATTLEPASS_LIST";
export const GET_BATTLEPASS_LIST_FAIL = "GET_BATTLEPASS_LIST_FAIL";
export const GET_BATTLEPASS_LIST_SUCCESS = "GET_BATTLEPASS_LIST_SUCCESS";

export const CREATE_BATTLEPASS = "CREATE_BATTLEPASS";
export const CREATE_BATTLEPASS_FAIL = "CREATE_BATTLEPASS_FAIL";
export const CREATE_BATTLEPASS_SUCCESS = "CREATE_BATTLEPASS_SUCCESS";

export const UPDATE_BATTLEPASS = "UPDATE_BATTLEPASS";
export const UPDATE_BATTLEPASS_FAIL = "UPDATE_BATTLEPASS_FAIL";
export const UPDATE_BATTLEPASS_SUCCESS = "UPDATE_BATTLEPASS_SUCCESS";

export const UPDATE_BATTLEPASS_STATUS = "UPDATE_BATTLEPASS_STATUS";
export const UPDATE_BATTLEPASS_STATUS_FAIL = "UPDATE_BATTLEPASS_STATUS_FAIL";
export const UPDATE_BATTLEPASS_STATUS_SUCCESS =
  "UPDATE_BATTLEPASS_STATUS_SUCCESS";

export const REMOVE_BATTLEPASS = "REMOVE_BATTLEPASS";
export const REMOVE_BATTLEPASS_FAIL = "REMOVE_BATTLEPASS_FAIL";
export const REMOVE_BATTLEPASS_SUCCESS = "REMOVE_BATTLEPASS_SUCCESS";

// -------------------------- STAFF & ROLE

export const GET_STAFF_LIST = "GET_STAFF_LIST";
export const GET_STAFF_LIST_FAIL = "GET_STAFF_LIST_FAIL";
export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";

export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const GET_ROLE_LIST_FAIL = "GET_ROLE_LIST_FAIL";
export const GET_ROLE_LIST_SUCCESS = "GET_ROLE_LIST_SUCCESS";

export const CREATE_STAFFROLE = "CREATE_STAFFROLE";
export const CREATE_STAFFROLE_FAIL = "CREATE_STAFFROLE_FAIL";
export const CREATE_STAFFROLE_SUCCESS = "CREATE_STAFFROLE_SUCCESS";

export const UPDATE_STAFFROLE = "UPDATE_STAFFROLE";
export const UPDATE_STAFFROLE_FAIL = "UPDATE_STAFFROLE_FAIL";
export const UPDATE_STAFFROLE_SUCCESS = "UPDATE_STAFFROLE_SUCCESS";

export const UPDATE_STAFFROLE_STATUS = "UPDATE_STAFFROLE_STATUS";
export const UPDATE_STAFFROLE_STATUS_FAIL = "UPDATE_STAFFROLE_STATUS_FAIL";
export const UPDATE_STAFFROLE_STATUS_SUCCESS =
  "UPDATE_STAFFROLE_STATUS_SUCCESS";

export const REMOVE_STAFFROLE = "REMOVE_STAFFROLE";
export const REMOVE_STAFFROLE_FAIL = "REMOVE_STAFFROLE_FAIL";
export const REMOVE_STAFFROLE_SUCCESS = "REMOVE_STAFFROLE_SUCCESS";

// -------------------------- PERMISSIONS
export const GET_PERMISSIONS_LIST = "GET_PERMISSIONS_LIST";
export const GET_PERMISSIONS_LIST_FAIL = "GET_PERMISSIONS_LIST_FAIL";
export const GET_PERMISSIONS_LIST_SUCCESS = "GET_PERMISSIONS_LIST_SUCCESS";

export const GIVE_PERMISSIONS = "GIVE_PERMISSIONS";
export const GIVE_PERMISSIONS_FAIL = "GIVE_PERMISSIONS_FAIL";
export const GIVE_PERMISSIONS_SUCCESS = "GIVE_PERMISSIONS_SUCCESS";

// -------------------------- BET Amounts

export const GET_BET_AMOUNTS = "GET_BET_AMOUNTS";
export const GET_BET_AMOUNTS_FAIL = "GET_BET_AMOUNTS_FAIL";
export const GET_BET_AMOUNTS_SUCCESS = "GET_BET_AMOUNTS_SUCCESS";

export const CREATE_BET_AMOUNT = "CREATE_BET_AMOUNT";
export const CREATE_BET_AMOUNT_FAIL = "CREATE_BET_AMOUNT_FAIL";
export const CREATE_BET_AMOUNT_SUCCESS = "CREATE_BET_AMOUNT_SUCCESS";

export const UPDATE_BET_AMOUNT = "UPDATE_BET_AMOUNT";
export const UPDATE_BET_AMOUNT_FAIL = "UPDATE_BET_AMOUNT_FAIL";
export const UPDATE_BET_AMOUNT_SUCCESS = "UPDATE_BET_AMOUNT_SUCCESS";

export const REMOVE_BET_AMOUNT = "REMOVE_BET_AMOUNT";
export const REMOVE_BET_AMOUNT_FAIL = "REMOVE_BET_AMOUNT_FAIL";
export const REMOVE_BET_AMOUNT_SUCCESS = "REMOVE_BET_AMOUNT_SUCCESS";

export const GET_DISPUTES = "GET_DISPUTES";
export const GET_DISPUTES_FAIL = "GET_DISPUTES_FAIL";
export const GET_DISPUTES_SUCCESS = "GET_DISPUTES_SUCCESS";

export const DELETE_DISPUTES = "DELETE_DISPUTES";
export const DELETE_DISPUTES_FAIL = "DELETE_DISPUTES_FAIL";
export const DELETE_DISPUTES_SUCCESS = "DELETE_DISPUTES_SUCCESS";

export const DECLARE_DISPUTE_RESULT = "DECLARE_DISPUTE_RESULT";
export const DECLARE_DISPUTE_RESULT_FAIL = "DECLARE_DISPUTE_RESULT_FAIL";
export const DECLARE_DISPUTE_RESULT_SUCCESS = "DECLARE_DISPUTE_RESULT_SUCCESS";

export const APPROVE_DISPUTE_PAYMENT = "APPROVE_DISPUTE_PAYMENT";
export const APPROVE_DISPUTE_PAYMENT_FAIL = "APPROVE_DISPUTE_PAYMENT_FAIL";
export const APPROVE_DISPUTE_PAYMENT_SUCCESS =
  "APPROVE_DISPUTE_PAYMENT_SUCCESS";

// -------------------------- Send Message

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";

export const FETCH_USER_MESSAGE = "FETCH_USER_MESSAGE";
export const FETCH_USER_MESSAGE_FAIL = "FETCH_USER_MESSAGE_FAIL";
export const FETCH_USER_MESSAGE_SUCCESS = "FETCH_USER_MESSAGE_SUCCESS";

export const DELETE_USER_MESSAGE = "DELETE_USER_MESSAGE";
export const DELETE_USER_MESSAGE_FAIL = "DELETE_USER_MESSAGE_FAIL";
export const DELETE_USER_MESSAGE_SUCCESS = "DELETE_USER_MESSAGE_SUCCESS";

export const DELETE_USER_PASS = "DELETE_USER_PASS";
export const DELETE_USER_PASS_FAIL = "DELETE_USER_PASS_FAIL";
export const DELETE_USER_PASS_SUCCESS = "DELETE_USER_PASS_SUCCESS";

// Reports Module
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORTS_FAIL = "FETCH_REPORTS_FAIL";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";

// Gateways Module
export const FETCH_GATEWAYS = "FETCH_GATEWAYS";
export const FETCH_GATEWAYS_FAIL = "FETCH_GATEWAYS_FAIL";
export const FETCH_GATEWAYS_SUCCESS = "FETCH_GATEWAYS_SUCCESS";

export const MANAGE_GATEWAYS = "MANAGE_GATEWAYS";
export const MANAGE_GATEWAYS_FAIL = "MANAGE_GATEWAYS_FAIL";
export const MANAGE_GATEWAYS_SUCCESS = "MANAGE_GATEWAYS_SUCCESS";

// SMS SERVICE Module
export const FETCH_SMS_SERVICE = "FETCH_SMS_SERVICE";
export const FETCH_SMS_SERVICE_FAIL = "FETCH_SMS_SERVICE_FAIL";
export const FETCH_SMS_SERVICE_SUCCESS = "FETCH_SMS_SERVICE_SUCCESS";

export const MANAGE_SMS_SERVICE = "MANAGE_SMS_SERVICE";
export const MANAGE_SMS_SERVICE_FAIL = "MANAGE_SMS_SERVICE_FAIL";
export const MANAGE_SMS_SERVICE_SUCCESS = "MANAGE_SMS_SERVICE_SUCCESS";


//
export const FETCH_GATEWAYS_TYPES = "FETCH_GATEWAYS_TYPES";
export const FETCH_GATEWAYS_TYPES_FAIL = "FETCH_GATEWAYS_TYPES_FAIL";
export const FETCH_GATEWAYS_TYPES_SUCCESS = "FETCH_GATEWAYS_TYPES_SUCCESS";


// Splash Image
export const SPLASH_IMAGE = "SPLASH_IMAGE";
export const SPLASH_IMAGE_FAIL = "SPLASH_IMAGE_FAIL";
export const SPLASH_IMAGE_SUCCESS = "SPLASH_IMAGE_SUCCESS";


export const REFERRAL_IMAGE = "REFERRAL_IMAGE";
export const REFERRAL_IMAGE_FAIL = "REFERRAL_IMAGE_FAIL";
export const REFERRAL_IMAGE_SUCCESS = "REFERRAL_IMAGE_SUCCESS";
