export const IMAGE_BASEURL = process.env.REACT_APP_BASEURL;
export const BASEURL = IMAGE_BASEURL + "backend/v1/";
export const BASEURL2 = IMAGE_BASEURL + "backend/v2/";
export const REPORT_BASEURL = IMAGE_BASEURL + "backend/v3/";
export const BASEURL3 = IMAGE_BASEURL + "backend/league/";

// Authorization
export const login_url = BASEURL + "signin";
export const update_profile = BASEURL + "update-profile";
export const update_password = BASEURL + "update-password";

// Tournaments
export const get_team_byGame = BASEURL + "get-team";
export const add_Tournament = BASEURL + "tournament-store";
export const join_team_inTour = BASEURL + "join-tournament";
export const duplicate_Tournament = BASEURL + "tournament-store-duplicate";
export const export_joined_team = BASEURL + "tournaments/export-joined-teams";

export const delete_Tournament = BASEURL + "tournament-delete";
export const update_Tournament = BASEURL + "tournament-update";
export const getTournament_list = BASEURL + "tournament-list";
export const reCreateTournaments = BASEURL + "recreate-tournament";

export const tour_status = BASEURL + "tournament-status";
export const tour_view = BASEURL + "tournament-view";
export const declare_slots = BASEURL + "declare-slots";
export const tour_running_status = BASEURL + "tournament-running-status";
export const tournament_csv_export = BASEURL + "tournaments/export-csv";

// Games
export const game_list = BASEURL + "game-list";
export const add_game = BASEURL + "game-store";
export const get_placement = BASEURL + "game-get-pp";
export const add_placement = BASEURL + "game-update-pp";

export const game_view = BASEURL + "game-view";
export const update_game = BASEURL + "game-update";
export const delete_game = BASEURL + "game-delete";
export const game_status = BASEURL + "game-status";
export const game_export = BASEURL + "games/export-csv";
export const game_played_list = BASEURL + "get-player-list";

// Games Types
export const add_game_type = BASEURL + "game-type-store";
export const game__type_list = BASEURL + "game-type-list";
export const delete_game_type = BASEURL + "game-type-delete";
export const update_game_type = BASEURL + "game-type-update";
export const game_status_type = BASEURL + "game-type-status";

// All Games
export const add_all_game = BASEURL + "all-game-store";
export const all_game__list = BASEURL + "all-game-list";
export const delete_all_game = BASEURL + "all-game-delete";
export const update_all_game = BASEURL + "all-game-update";
export const all_game_status = BASEURL + "all-game-status";
export const all_game_export = BASEURL + "all-games/export-csv";

// User
export const user_list = BASEURL + "user-list";
export const user_view = BASEURL + "user-view";
export const verify_user_phone = BASEURL + "user/mobile-status";
export const verify_user_email = BASEURL + "user/email-status";
export const manage_user_pass = BASEURL + "store-user-pass";
export const unsubscribe_user_plan = BASEURL + "unsubscribe-user-plan";
export const export_user_transaction = BASEURL + "users/export-transaction-csv";

export const update_user = BASEURL + "user-update";
export const export_user_csv = BASEURL + "users/export-csv";

export const delete_user = BASEURL + "user-delete";
export const user_status = BASEURL + "user-status";
export const user_cool_down = BASEURL + "user/cooldown-status";
export const update_user_wallet = BASEURL + "update-user-wallet";

// Transction Logs
export const transaction_logs = BASEURL + "transaction-all-list";
export const withdraw_requests = BASEURL + "withdraw-request-list";
export const approve_requests = BASEURL + "approve-request";
export const transaction_csv_export = BASEURL + "transactions/export-csv";

// Teams
export const team_list = BASEURL + "team-list";
export const user_pass_list = BASEURL + "user/passes";
export const user_team_list = BASEURL + "user/team-list";
export const team_log_list = BASEURL + "get-tournament-team-log-list";

export const team_status_update = BASEURL + "update-team-status";
export const team_view = BASEURL + "team-view";
export const team_delete = BASEURL + "team-delete";
export const team_player_delete = BASEURL + "player-delete";
export const all_team_list = BASEURL + "all-teams";
export const delete_joined_tournament = BASEURL + "delete-joined-tournament";
export const teams_csv_export = BASEURL + "teams/export-csv";

// Players
export const player_list = BASEURL + "player-list";
export const player_view = BASEURL + "player-view";

// Notification
export const notification_list = BASEURL + "notification-list";
export const add_notification = BASEURL + "notification-store";
export const delete_notification = BASEURL + "notification-delete";
export const notification_status = BASEURL + "notification-status";
export const notification_csv_export = BASEURL + "notifications/export-csv";

// Settings
export const view_setting = BASEURL + "setting-view";
export const update_setting = BASEURL + "setting-update";
export const get_subscriber_counts = BASEURL + "get-subscriber-count";
export const get_subscribers = BASEURL + "get-subscribers";

// TDM Players
export const tdp_player_view = BASEURL + "player-view";
export const game_player_list = BASEURL + "geme-played-by-user";
export const game_played_by_user = BASEURL + "game-played-by-user";
export const tdm_player_view = BASEURL + "view-player";

// Create Room Id
export const create_room_id = BASEURL + "update-room-details";

// Bulk Ranks Update
export const update_rank = BASEURL + "update-rank";
export const bulk_update_rank = BASEURL + "bulk-update-rank";

export const settlement_status = BASEURL + "settlement-status";

// Sponsor List
export const sponsor_list = BASEURL + "sponsor-list";
export const add_sponsor = BASEURL + "sponsor-store";
export const update_sponsor = BASEURL + "sponsor-update";
export const delete_sponsor = BASEURL + "sponsor-delete";
export const sponsor_status = BASEURL + "sponsor-status";

// Host
export const host_list = BASEURL + "host-list";
export const add_host = BASEURL + "host-store";
export const update_host = BASEURL + "host-update";
export const delete_host = BASEURL + "host-delete";
export const host_status = BASEURL + "host-status";

// dashboard record
export const record_count = BASEURL + "get-count";
export const registration_count = BASEURL + "get-user-registration-count";
export const device_count = BASEURL + "get-device-count";

export const total_amount = BASEURL + "get-total-amount";
export const transaction_list = BASEURL + "get-transaction-list";
export const transaction_list_by_id = BASEURL + "transaction-list";

export const add_support = BASEURL + "support-update";
export const view_support = BASEURL + "support-view";

// update streaming link
export const update_streaming_link = BASEURL + "update-streaming-link";

// - league section
// 1.
export const create_stage = BASEURL2 + "stage-store";
export const update_stages = BASEURL2 + "stage-update";
export const delete_stages = BASEURL2 + "stage-delete";
export const update_stage_status = BASEURL2 + "stage-status";
export const get_stages_list = BASEURL2 + "stage-list";
export const get_stage_round_list = BASEURL2 + "league-tournaments";
export const declare_slots_lobby =
  BASEURL2 + "league-tournaments/declare-slots";

export const league_stage_csv_export = BASEURL2 + "stages/export-csv";
export const league_csv_export = BASEURL2 + "league-tournaments/export-csv";
// 2.
export const league_tour_list = BASEURL2 + "tournament-list";
export const league_tour_view = BASEURL2 + "tournament-view";
export const league_tour_create = BASEURL2 + "tournament-store";
export const league_tour_delete = BASEURL2 + "tournament-delete";
export const league_tour_update = BASEURL2 + "tournament-update";
export const league_tour_status = BASEURL2 + "tournament-status";
export const league_tour_stage = BASEURL2 + "get-tournament-stages";
export const stage_amount_settle = BASEURL2 + "stages-settlement-status";

export const league_tour_teams =
  BASEURL2 + "league-tournaments/get-joined-teams";
export const export_league_tour_teams =
  BASEURL2 + "league-tournaments/export-joined-teams";
export const delete_league_tour_stage = BASEURL2 + "delete-tournament-stage";
export const update_league_tour_stage = BASEURL2 + "league-tournaments";
export const delete_league_team =
  BASEURL2 + "league-tournaments/delete-league-team";

export const league_tour_running_status =
  BASEURL2 + "tournament-running-status";

// export const create_round_group = BASEURL + "league-tournaments/stages/rounds";
export const create_round_group = BASEURL2 + "league-tournaments/stages/rounds";
export const update_round_group = BASEURL2 + "league-tournaments/update-lobby";

export const delete_round_group = BASEURL2 + "league-tournaments/stages/rounds";
export const stage_result_status = BASEURL2 + "stages-result-status";
export const round_running_status =
  BASEURL2 + "league-tournaments/stages/round";

// Subscriptions

export const subscription_list = BASEURL + "subscription-plan/list";
export const add_subscription = BASEURL + "subscription-plan/store";
export const update_subscription = BASEURL + "subscription-plan/update";
export const delete_subscription = BASEURL + "subscription-plan/delete";
export const subscription_status = BASEURL + "subscription-plan/status";

// Banner

export const banner_list = BASEURL + "banner-list";
export const add_banner = BASEURL + "banner-store";
export const update_banner = BASEURL + "banner-update";
export const delete_banner = BASEURL + "banner-delete";
export const banner_status = BASEURL + "banner-status";

// leader board banner
export const leader_banner_list = BASEURL + "leaderboard/banner-list";
export const add_leader_banner = BASEURL + "leaderboard/banner-store";
export const update_leade_banner = BASEURL + "leaderboard/banner-update";
export const delete_leader_banner = BASEURL + "leaderboard/banner-delete";
export const leader_banner_status = BASEURL + "leaderboard/banner-status";

// ranks
export const get_leaderboard_rank = BASEURL + "game/get-leaderboard-ranks";
export const add_leaderboard_rank = BASEURL + "game/update-leaderboard-ranks";

// result
export const get_lastweek_result = BASEURL + "game/leaderboard/last-week";
export const get_currentweek_result = BASEURL + "game/leaderboard/current-week";

// Battle Pass
export const get_passes_result = BASEURL + "pass/list";
export const get_features_result = BASEURL + "feature-list";

export const new_passes = BASEURL + "pass/store";
export const new_features = BASEURL + "feature-store";

export const update_passes = BASEURL + "pass/update";
export const update_features = BASEURL + "feature-update";

export const update_status_passes = BASEURL + "pass/status";
export const update_status_features = BASEURL + "feature-status";

export const remove_passes = BASEURL + "pass/delete";
export const remove_features = BASEURL + "feature-delete";

// STAFF & ROLE
export const get_roles_result = BASEURL + "role-list";
export const get_staff_result = BASEURL + "staff-list";

export const new_role = BASEURL + "role-store";
export const new_staff = BASEURL + "staff-store";

export const update_role = BASEURL + "role-update";
export const update_staff = BASEURL + "staff-update";

export const update_status_role = BASEURL + "role-status";
export const update_status_staff = BASEURL + "staff-status";

export const remove_role = BASEURL + "role-delete";
export const remove_staff = BASEURL + "staff-delete";

// PERMISSIONS
export const get_permissions_result = BASEURL + "permission-view";
export const create_permissions = BASEURL + "permission-store";

// TDM
export const betamount = BASEURL2 + "bet-amount";
export const disputes_list = BASEURL2 + "get-dispute-list";
export const logs_list = BASEURL2 + "get-tdm-log-list";
export const delete_log = BASEURL2 + "delete-tdm-log";
export const tdm_transaction = BASEURL + "transactions/tdm-list";
export const get_tdm_players = BASEURL2 + "get-tdm-player-list";
export const export_tdm_players = BASEURL2 + "export-tdm-player-list";

export const disputes_result = BASEURL2 + "declare-winner";
export const disputes_delete = BASEURL2 + "delete-dispute";
export const disputes_payment = BASEURL2 + "approve-winner-payment";

// MESSAGES
export const send_user_message = BASEURL + "message-store";
export const fetch_user_message = BASEURL + "message-list";
export const delete_user_message = BASEURL + "message-delete";
export const delete_user_pass = BASEURL + "delete-user-pass";
export const deactivate_user_pass = BASEURL + "activate-deactivate-user-pass";

// daily matches list
export const daily_matches_list = BASEURL + "get-daily-matches-team-list";

// reports module endpoints
export const gst_tsx_resport = REPORT_BASEURL + "gst-transaction-report";
export const tds_tsx_resport = REPORT_BASEURL + "tds-transaction-report";
export const debit_credit_tsx_resport =
  REPORT_BASEURL + "get-debit-credit-report";

// manage upi's module endpoints
export const resource_list = REPORT_BASEURL + "resource-list";
export const manage_upi = REPORT_BASEURL + "upi-payment-gateway";
export const gateway_type_list = REPORT_BASEURL + "gateway-type-list";


// manage gateways's module endpoints
export const manage_payment_gatways = REPORT_BASEURL + "payment-gateway";

// manage sms services
export const manage_sms_services = REPORT_BASEURL + "sms-service";

// withdraw
export const get_beneficiary = REPORT_BASEURL + "get-beneficiary";
export const initiate_payout = REPORT_BASEURL + "initiate-payout";


// splash image
export const get_splash_image = BASEURL + "setting/get-splash-image";
export const update_splash_image = BASEURL + "setting/update-splash-image";


//referal image

export const get_referral_image = BASEURL + "setting/get-referral-image";
export const update_referral_image = BASEURL + "setting/update-referral-image";


